var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add_goods_content" },
    [
      _c(
        "page-content",
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getListChange },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("商品:")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.searchParams.goodsName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchParams,
                          "goodsName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchParams.goodsName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "商品ID", "min-width": "90" },
              }),
              _c("el-table-column", {
                attrs: { prop: "imagePath", label: "商品图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: scope.row.imagePath, alt: "" },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商品名称", "min-width": "120" },
              }),
              _c("el-table-column", {
                attrs: { label: "分销时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-date-picker", {
                          key: _vm.itemKey,
                          staticStyle: { width: "200px" },
                          attrs: {
                            type: "datetime",
                            "value-format": "timestamp",
                            size: "mini",
                            format: "yyyy-MM-dd HH:mm",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeTime($event, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.validStartTime,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "validStartTime", $$v)
                            },
                            expression: "scope.row.validStartTime",
                          },
                        }),
                        _vm._v(" 至 "),
                        _c("el-date-picker", {
                          key: _vm.itemKey2,
                          staticStyle: { width: "200px" },
                          attrs: {
                            type: "datetime",
                            size: "mini",
                            "value-format": "timestamp",
                            "default-time": "23:59:59",
                            format: "yyyy-MM-dd HH:mm",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeTime2($event, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.validEndTime,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "validEndTime", $$v)
                            },
                            expression: "scope.row.validEndTime",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.toJoin(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 参加分销 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.page,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.page_count,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.item_total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.changeGoodsVisible
        ? _c("add-goods-dialog", {
            attrs: {
              "change-goods-visible": _vm.changeGoodsVisible,
              "goods-row": _vm.changeGoodsRow,
              "open-type": _vm.openType,
            },
            on: {
              "update:changeGoodsVisible": function ($event) {
                _vm.changeGoodsVisible = $event
              },
              "update:change-goods-visible": function ($event) {
                _vm.changeGoodsVisible = $event
              },
              onupdatetable: _vm.updateTableData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }