<template>
  <div class="add_goods_content">
    <page-content>
      <search-panel
        flex
        show-btn
        @getList="getListChange"
      >
        <div>
          <p>商品:</p>
          <el-input
            v-model.trim="searchParams.goodsName"
            placeholder="请输入商品名称"
            size="mini"
            clearable
          />
        </div>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        element-loading-text="拼命加载中"
      >
        <el-table-column
          prop="id"
          label="商品ID"
          min-width="90"
        />
        <el-table-column
          prop="imagePath"
          label="商品图片"
          width="150"
        >
          <template slot-scope="scope">
            <div>
              <img
                :src="scope.row.imagePath"
                alt=""
                style="width: 100px;height: 100px;"
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="商品名称"
          min-width="120"
        />
        <!-- <el-table-column
                    prop="sellPrice"
                    label="销售价（元）"
                /> -->
        <el-table-column
          label="分销时间"
          min-width="150"
        >
          <!-- :value="[scope.row.validStartTime, scope.row.validEndTime]"   range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" @change="changeTime(value, scope.row)"
                            value-format="timestamp"
                            :default-time="['00:00:00', '23:59:59']"-->
          <template slot-scope="scope">
            <el-date-picker
              :key="itemKey"
              v-model="scope.row.validStartTime"
              type="datetime"
              value-format="timestamp"
              size="mini"
              style="width: 200px"
              format="yyyy-MM-dd HH:mm"
              @input="changeTime($event, scope.row)"
            /> 至
            <el-date-picker
              :key="itemKey2"
              v-model="scope.row.validEndTime"
              type="datetime"
              size="mini"
              value-format="timestamp"
              style="width: 200px"
              default-time="23:59:59"
              format="yyyy-MM-dd HH:mm"
              @input="changeTime2($event, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="150"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="toJoin(scope.row)"
            >
              参加分销
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.page_count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.item_total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <!-- 修改商品库存弹框 -->
    <add-goods-dialog
      v-if="changeGoodsVisible"
      :change-goods-visible.sync="changeGoodsVisible"
      :goods-row="changeGoodsRow"
      :open-type="openType"
      @onupdatetable="updateTableData"
    />
  </div>
</template>

<script>
import addGoodsDialog from './components/addAndEditGoods.vue'

export default {
  name: 'AddGoods',
  components: {
    addGoodsDialog
  },
  data() {
    return {
      searchParams: {
        goodsName: ''
      },
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        page_total: 0,
        item_total: 0,
        page_count: 10
      },
      changeGoodsVisible: false,
      changeGoodsRow: {},
      openType: 'add',
      itemKey: null,
      itemKey2: null

    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    changeTime(event, row) {
      this.itemKey = Math.random()
    },
    changeTime2(event, row) {
      this.itemKey2 = Math.random()
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val
      this.pagination.page = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.page = val
      this.getList()
    },
    getListChange() {
      this.pagination.page = 1
      this.getList()
    },
    // 参加活动
    toJoin(row) {
      this.changeGoodsVisible = true
      this.changeGoodsRow = row
    },
    updateTableData() {
      this.getList()
    },
    getList() {
      let _this = this
      _this.loading = true
      let params = {
        ...this.searchParams,
        currentPage: _this.pagination.page,
        pageSize: _this.pagination.page_count
      }
      _this.$axios.post(
        _this.$api.DistributeController.getAllGoods,
        params
      ).then(function(res) {
        if (res.code === 0) {
          const { size, total, current, pages } = res.data;
          _this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total)
          }
          _this.tableData = res.data.records
        } else {
          _this.tableData = []
          _this.pagination.item_total = 0
        }
        _this.loading = false
      }).catch(function(error) {
        console.log(error)
      })
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.goods_message {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    .left {
      margin: 10px 15px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .right {
      .title {
        font-weight: 700;
        margin: 10px 0;
      }
      .id {
        color: #999;
      }
    }
  }
</style>
